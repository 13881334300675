import {graphql, useStaticQuery} from "gatsby";
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

export default function GeoMap() {
  const { site } = useStaticQuery(graphql`
    query GeoSiteQuery {
      site {
        siteMetadata {
          location
          zoom
          title
        }
      }
    }
  `);

  if (typeof window === 'undefined') {
    return null;
  }

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <MapContainer
      center={site.siteMetadata.location}
      zoom={site.siteMetadata.zoom}
      style={{ height: '350px' }}
      scrollWheelZoom={false}
    >
      <TileLayer
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;https://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
      />
      <Marker position={site.siteMetadata.location}>
        <Popup>{site.siteMetadata.title}</Popup>
      </Marker>
    </MapContainer>
  );
}
